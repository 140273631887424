@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bottom-loader-section{
  background-color: #f7f8fd;
  padding: 20px;
}

.outer-spinner{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #f7f8fd;
  border-top: 4px solid #ff0050;
  border-bottom: 4px solid #00f2ea;
  animation-name: rotate-loader-right;
  animation-duration: 1000ms;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-delay: 100ms;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: forwards;
  margin: auto;
  background-color: #f7f8fd;
}

.inner-spinner{
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 4px solid #f7f8fd;
  border-left: 4px solid #000;
  border-right: 4px solid #000;
  animation-name: rotate-loader-left;
  animation-duration: 1000ms;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-delay: 100ms;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: forwards;
  margin: auto;
  background-color: #f7f8fd;
  margin: auto;
}

.search-list-end-message{
  background-color: #f7f8fd;
  text-align: center;
  padding: 20px;
}

.date-range-picker-container{
  position: relative;
}

.date-range-picker{
  position: absolute;
}


.image-not-found{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  /* height: 100%; */
  height: 85%;
  min-height: 500px;
}

.image-not-found > h2{
  color: lightgray;
  text-align: center;
}


@keyframes rotate-loader-right {
  from{
    rotate: 0deg;
  }
  to{
    rotate: 360deg;
  }
}

@keyframes rotate-loader-left {
  from{
    rotate: 0deg;
  }
  to{
    rotate: -720deg;
  }
}