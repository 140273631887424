@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

/*  Simple Scroll */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(107, 114, 128, 255);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, .9);
}

body{
  font-family: "Figtree", sans-serif;
  overflow-x: hidden;
  box-sizing: border-box;
}

footer {
  .email-btn {
    align-items: center;
    // background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    display: flex;
    font-size: 15px;
    font-weight: 500;
    justify-content: center;
    margin: 10px 0;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none !important;
  }
  
  .whole-footer {
    width: 100%;
    padding: 0px 70px;

    .heading {
      font-weight: 700;
    }
  }

  .flex {
    img {
      width: 100px;
    }

    p {
      font-size: 13px;
      color: #999;
    }

    .section {
      font-size: 16px;
      margin-bottom: 20px;
      color: #4c4949;
    }

    .url {
      margin-bottom: 3px;
      font-weight: 400;
    }

    a {
      color: #374151;
      font-weight: 400;
      margin-bottom: 10px;
      text-decoration: none;
      text-align: center;
    }

    .last-link {
      margin-bottom: 25px;
    }
  }

  .social-icons {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 25px;
    margin-bottom: 15px;

    .fa-brands {
      font-size: 30px;
      color: #ab0fba;
      margin: 0 15px;
      font-weight: 500;
    }
  }
}

 /* Additional styling for hover effects and transitions */
 .form-input, .form-select {
  transition: box-shadow 0.3s ease-in-out;
}
.form-input:hover, .form-select:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
.video-card:hover {
  transform: translateY(-5px);
  transition: transform 0.3s ease-in-out;
}


.HomepageContainer {
  background: #f7f8fd;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}

.card-heading{
  font-size: 18px;
    line-height: 22px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    padding-bottom: 10px;
}

.react-datepicker-wrapper{
  width: 100%;
}
.react-datepicker__day--selected,.react-datepicker__day--keyboard-selected{
  background: linear-gradient(135deg, rgb(0, 147, 255) 0%, rgb(238, 29, 210) 100%) !important;
  color: #fff !important;
}

.gradient-text {
  background: linear-gradient(to right, #ff69b4, #007bff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.active {
  @apply border-red-500;
  @apply border-b-2;
  @apply text-black;
}